import React from 'react';
import ContactForm from './ContactForm';
import config from '../../config';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Link } from 'gatsby'



export default function Footer() {
  return (
  <footer id="footer" className="box">
    <div className="box">
    <article className="inner">
      <div id="footer">
        <header className="box">
          <h2>Follow us</h2>
          <article align="center">
            <ul className="icons h2">
              {config.socialLinks.map(social => {
                const { icon, name, url } = social;
                return (
                  <li key={url}>
                    <a href={url} className={`icon ${icon}`}>
                      <span className="label" aria-label="Connect with us using social media">{name}</span>
                    </a>
                  </li>
                );
              })}
            </ul>
          </article>
        </header>
        <div className="box">
          <section className="tiles">
            <article className="box">
              <ContactForm />
            </article>
            <article className="box">
              <Row>
                <Col>
                  <Card>
                    <Card.Header className="icon h5 fa-map-marker">  USA</Card.Header>
                    <Card.Body>919 N Market St, Suite 950 Wilmington, DE 19801 USA</Card.Body>
                    <Card.Footer>
                      <span className="icon  fa-mobile"> +001-6475685414</span> <br /> 
                      <span className="icon  fa-envelope"><a href="mailto:info@svatva.co"> info@svatva.co</a> </span>
                    </ Card.Footer>
                  </Card>
                </Col><br />
                <br />
                <Col>
                  <Card>
                    <Card.Header className="icon h5 fa-map-marker">  India</Card.Header>
                    <Card.Body>5th block 2nd floor, 99D, 2nd Cross Rd, KHB Colony, 5th Block, Koramangala, Bengaluru, Karnataka 560095</Card.Body>
                    <Card.Footer>
                      <span className="icon  fa-mobile"> +91 - 9886652512</span> <br /> 
                      <span className="icon  fa-envelope"><a href="mailto:info@svatva.co"> info@svatva.co</a> </span>
                    </ Card.Footer>
                  </Card>
                </Col>
              </Row>
            </article>
            <article className="box">
              <div id="site-links" className="box">
                <ul className="actions">
                  <li>
                    <Link to="/sitemap.xml">Sitemap</Link>
                  </li>
                  <li>
                    <Link to="/PrivacyTerms">Privacy Terms</Link>
                  </li>
                  <li>
                    <Link to="/CookiePolicy">Cookie Policy</Link>
                  </li>
                </ul>
              </div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <div id="home" className="box">
                <section className="main special" align="center">
                  <ul className="actions" align="center">
                    <li>
                      <Link to="/" className="button">
                        <font weight="bold">
                          Home
                        </font>
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
            </article>
          </section>
        </div>
      </div>
      </article>
    </div>
  </footer>
  );
}
