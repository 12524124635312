import React from 'react';
import Scrollspy from 'react-scrollspy'
import Scroll from './Scroll'

export default function Nav({ onClose = () => {} }) {
  return (
    <nav id="menu">
      <div className="inner">
        <h2>Menu</h2>
        <Scrollspy items={ ['header','home','about-us', 'services', 'clients', 'our-clients','testimonials','contact'] } currentClassName="is-active" offset={-300}>
           
            <li>
                <Scroll type="id" element="header">
                    <a href="/">Home</a>
                </Scroll>
            </li>
            <li>
                <Scroll type="id" element="about-us">
                    <a href="/about">About Us</a>
                </Scroll>
            </li>
            <li>
                <Scroll type="id" element="services">
                    <a href="/services">Services</a>
                </Scroll>
            </li>
            {/* <li>
                <Scroll type="id" element="clients">
                    <a href="/clients">Clients</a>
                </Scroll>
            </li> */}
            <li>
                <a href="/blog" >
                    Blog
                </a>
            </li>
            <li>
                <Scroll type="id" element="contact">
                    <a href="/contact">Contact</a>
                </Scroll>
            </li>
        </Scrollspy>
      </div>
      <a
        className="close"
        onClick={e => {
          e.preventDefault();
          onClose();
        }}
        href="#menu"
      >
        Close
      </a>
    </nav>
  );
}
