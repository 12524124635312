module.exports = {
  siteTitle: 'SVATVA', // <title>
  siteUrl: 'https://svatva.co', // Domain of your site. No trailing slash!
  manifestName: 'SVATVA',
  manifestShortName: 'svatva-home', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/logo.svg',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Svatva',
  subHeading: 'Business consulting with limitless potential  ',
  // social
  socialLinks: [
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/svatvaco',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/svatvaco',
    },
    {
      icon: 'fa-facebook',
      name: 'Facebook',
      url: 'https://facebook.com/svatva',
    },
  ],
};
