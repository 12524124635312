import React, { useState } from 'react';
import Nav from './Nav';
import TopNav from './TopNav';
import { Link } from 'gatsby';
import logo from '../assets/images/logo.svg';
export default function SideBar({ sections = [] }) {
  const [headerOpen, toggleHeader] = useState(false);
  return (
    <>
      <header id="header">
        <div className="inner">
          <section className="tiles">
          <Link to="/" className="logo">
            <span className="logo"><img src={logo} alt="" />   </span>
          </Link>
          <h1>SVATVA</h1><br />
          
          <TopNav onMenuClick={() => toggleHeader(!headerOpen)} />
          
          </section>
          
        </div>
        
      </header>
      <div className={`${headerOpen ? 'is-menu-visible' : ' '}`}>
        <Nav onClose={() => toggleHeader(!headerOpen)} />
      </div>
    </>
  );
}
