import React from 'react';
export default function ContactForm() {
  return (
    <section id="contact">
      <h2>Get in touch</h2>
      <form method="post" action="https://script.google.com/macros/s/AKfycbzPjZOIgfYDU1wQDkot5wYHoWSaiXTHwg1ykH9_c-N2t9CpYtY/exec">
        <div className="fields">
          <div className="field half">
            <input type="text" id="name" aria-label="name" placeholder="Name" />
          </div>
          <div className="field half">
            <input type="email" id="email" aria-label="email" placeholder="Email" />
          </div>
          <div className="field">
            <textarea name="message"  aria-label="message" placeholder="Message" />
          </div>
        </div>
        <ul className="actions">
          <li>
            <input type="submit" value="Send" className="primary" />
          </li>
        </ul>
        <article className="hidden">
        <div>
          <h2><em>Thanks</em> for contacting us! We will get back to you soon!
          </h2>
        </div>
        </article>
      </form>
      <script data-cfasync="false" type="text/javascript" src="../assets/form-submission-handler.js"></script>
      <script data-cfasync="false" type="text/javascript" src="../../gatsby-browser.js"></script>
    </section>
  );
}
